import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from "../../types";

const TOP_BACKGROUND_HEIGHT = "1.45vw";
const BOTTOM_BACKGROUND_HEIGHT = "1.88vw";


export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;

`;

export const Wrapper = styled.section<Visibility>`
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top:  90px;
  // margin-bottom: calc(95px + ${BOTTOM_BACKGROUND_HEIGHT});
  // padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    margin-bottom: calc(60px + ${BOTTOM_BACKGROUND_HEIGHT});
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top:40px;
    margin-bottom:0px;
  }

  // &:after {
  //   content: "";
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  //   background-size: contain;
  //   height: 180px;
  //   bottom: -114px;
  //   background: url('/salespage/9th section/bottom-beige-path-coverup.svg') no-repeat center 0;
  //   background-size: 100% 100px;

  //   @media screen and (max-width: ${breakpoints.lg}px) {
  //     transform: scale(1.1);
  //     height: 118px;
  //   }
  //   @media screen and (max-width: ${breakpoints.md}px) {
  //       height:90px; bottom: -71px;
  //     }
  // }
`;

export const Heading = styled.header`
  width: 100%;
  margin-bottom: 33px;
  text-align: center;
  position:relative;

  &:before {
    content:'';
    background-image: url("/salespage/9th section/blue-arrow-down.svg"); 
    background-repeat:no-repeat;width:100px;height:250px;
    margin-left: 0px;
    float: left;z-index:-1;
    @media screen and (max-width: ${breakpoints.md}px) {
     background-size:50%;position:absolute;left:-30px;height:130px; 
    }
    
    @media screen and (max-width:720px) {
      left:-37px;
    }
    @media screen and (max-width:693px) {
      left:0px;
    }
    @media screen and (max-width:640px) {
      left:0px;
    }
  }
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 700;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width:640px) {
    font-size: 24px; padding:0 37px; 
}
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 24px
}
`;

export const SubTitle = styled.h3`

  /*color: #9d978b;
  font-weight: 900;*/
  font-size: 30px;
  line-height: ${dimensions.lineHeight.large};
  position: absolute;
  text-align: center;
  width: 45%;
  z-index: 2;
`;

export const CheckListWrapper = styled.section`
 
  width: 1170px;
 

  margin: auto;
  font-size: 15px;
  
  @media screen and (max-width: ${breakpoints.xl}px) {
    width: 100%;
  }
`;

export const CheckListContainer = styled.div`
  width: 100%;
  margin: auto;
  background-color: #fff;
  height: auto;
  padding: 30px 10px 20px 50px;
  margin-top: 70px;
  margin-bottom: 70px;
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 30px 15px 20px 15px;
    height:auto;
    display:inline-block;
    margin-bottom:0;
  }
 

`;

export const CheckListContainerBlue = styled.div`
  
 
    width: 950px;
 

  margin: auto;
  background-color: #0a2460;
  height: 250px;
  border-radius: 5px;
  margin-top: 30px;
  color:#fff;
  padding: 30px 10px 20px 50px;
  position:relative;
  @media screen and (max-width: ${breakpoints.lg}px) {
      width:100%;
      padding: 30px 10px 20px 15px;
      height:300px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    height:500px;
  }


  &:after {
    content: '';
    background: url("/salespage/9th section/blue-arrow-right.svg") no-repeat, url("/salespage/9th section/and-much-more.svg") no-repeat; 
    background-position: left top, right bottom;
    width: 380px;
    height: 120px;
    display: block;
    float:right;
    margin-right: -160px;
    background-size: auto;
    @media screen and (max-width: ${breakpoints.xl}px) {
      margin-right: -60px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      margin-right: 30px;
      height: 85px;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      background: url("/salespage/9th section/blue-arrow-right.svg") no-repeat;
      position:absolute; background-size:75%;margin-bottom:-70px;
      width:100px;
    bottom:0;

      // margin-right:0;width:300px;
      // position:absolute;
      // bottom:-50px;
    }
  }

  &:before{ 
    content: '';
    position:absolute;
    bottom:0;
    @media screen and (max-width: ${breakpoints.sm}px) {
      background: url("/salespage/9th section/and-much-more.svg") no-repeat; 
      background-position:right top; background-size: auto;bottom:0;margin-bottom:-50px;
      width: 230px;
      height: 30px;
      background-size: 60%;

    }
  }
`;

export const CheckListTitle = styled.div`
 
    width: 1000px;
    font-size: ${dimensions.fontSize.regular}px;
 
 
  margin: auto;
  z-index: 2;
  
  font-weight: 600;
  margin-top: -100px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    padding:0 30px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    position:rleative;padding-left:90px;
 
      font-size:  ${dimensions.fontSize.regular * 0.9}px;
 
    
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    width:100%;
 
      font-size:  ${dimensions.fontSize.regular * 0.8}px;
 
    
  }

  &:before {
    content: url("/salespage/9th section/asterisk.svg"); 
    width: 86px;
    height: 42px;
    display: block;
    float:left;
    margin-top: -13px;
    margin-right: 10px;
    @media screen and (max-width: ${breakpoints.md}px) {
      position:Absolute;left:0;margin-top:-6px;
    }
  }
`;

export const CheckListTitleWhite = styled.h2`
width: 850px;
max-width: 1000px;
margin: auto;
z-index: 2;
font-size: 30px;
font-weight: 900;
margin-top: 100px;
text-align: center;
@media screen and (max-width: ${breakpoints.lg}px) {
  width: 100%;
  max-width: 100%;
}
@media screen and (max-width: ${breakpoints.md}px) {
position:relative;padding:0 35px;
}
@media screen and (max-width: 349px) {
  font-size: 26px;
}

  &:before {
    content: url("/salespage/9th section/praise-hands.svg"); 
    width: 86px;
    height: 42px;
    display: block;
    float:left;
    margin-top: -80px;
    margin-left: 20px;
    z-index:-1;
    @media screen and (max-width: ${breakpoints.md}px) {
      content:'';
      background-image: url("/salespage/9th section/praise-hands.svg"); 
      background-repeat:no-repeat;background-size:100%;width:100px;
      height:120px;position:absolute;left:30px;margin-top:-40px;margin-left:0;
    }
    @media screen and  (max-width: ${breakpoints.md}px)  {
      left:0;right:0;bottom:-100px;margin:0 auto;
    }   
    @media screen and (max-width: 479px) {
      background-size:90%;
    }
  }
`;

export const CheckListBlue = styled.div`
  &:before {
    content: url("/salespage/9th section/bullet-green-check.svg"); 
    margin-right: 10px
  }
  float: left;

  font-size: 13px;
 
 
    font-size:  ${dimensions.fontSize.medium}px;
    width: 280px;
 

  //background-color:red;
  padding: 7px;
  margin-right: 8px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    width:48%;
    
  }
  @media screen and (max-width: ${breakpoints.md}px) {
 
      font-size:  ${dimensions.fontSize.medium * 0.9}px;
 
   
  }

  
  @media screen and (max-width: ${breakpoints.sm}px) {
    width:100%;
 
      font-size:  ${dimensions.fontSize.medium * 0.8}px;
 
  }
    
  @media screen and (max-width: ${breakpoints.xs}px) {
 
      font-size:  ${dimensions.fontSize.medium * 0.7}px;
 
  }
`;

export const CheckListSubTitle = styled.div`
  font-weight: 600;
  margin-bottom: 20px;  

 
    font-size:  ${dimensions.fontSize.medium}px;
    @media screen and (max-width: ${breakpoints.md}px) {
   
      font-size:  ${dimensions.fontSize.medium * 0.9}px;
    } 
    
    @media screen and (max-width: ${breakpoints.sm}px) {
      font-size:  ${dimensions.fontSize.medium * 0.8}px;
    }
 

}


`;

export const CheckList = styled.div`
  &:before {
    content: url("/salespage/9th section/check-bullet.svg"); 
    margin-top: -10px;
    position: absolute;
    margin-left: -80px;
    @media screen and (max-width: ${breakpoints.sm}px) {
      content:'';
      background-image: url("/salespage/9th section/check-bullet.svg"); 
      background-repeat:no-repeat;
      background-size:100%;
      width:60px;
      height:60px;
    }
  }
  margin-left: 50px;
  height: 80px;
  float: left;
 
  font-weight: 900;
  width: 300px;
  margin-right: 50px;
 
    font-size:  ${dimensions.fontSize.medium}px;
    width: 450px;
    margin-right: 30px;
 
  
  
  //background-color:red;
  padding: 7px;
  
  vertical-align: middle;
  margin-left: 70px;
  margin-bottom: 20px;

  @media screen and (max-width: ${breakpoints.xl}px) {
    width:33%;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width:80%;
    height:auto;display:inline-block;margin-bottom:40px;
    &:last-child{margin-bottom:0;}

 
      font-size:  ${dimensions.fontSize.medium * 0.9}px;
 
    
  } 

  @media screen and (max-width: ${breakpoints.sm}px) {
 
      font-size:  ${dimensions.fontSize.medium * 0.8}px;
 
    
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
 
      font-size:  ${dimensions.fontSize.medium * 0.7}px;
 
  }

  div{
    margin-top: 10px;
  }



`;

export const LeftColumn = styled.div`
  padding-right: 20px;
  margin-left: 60px;
`;

export const Content = styled.div`
  color: ${colors.lightGray};
  font-size: ${dimensions.fontSize.medium}px;
  line-height: 1.63;

  p {
    margin: 26px 0;
    font-weight: 600;
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: ${dimensions.fontSize.medium * 0.9}px;
  }
`;

export const RightColumn = styled.figure`
  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 0;
    padding: 0;
  }
`;

export const Illustration = styled.img`
  transform: translate(21px, -12px);

  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 80%;
    transform: none;
    margin:-50px 0 -70px 0;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;
  }
  @media screen and (max-width: 400px) {
    margin:-30px 0 -50px 0;

  }
`;

export const FeatureParagraphMarkLong = styled.span`
  position: relative;

  &:before {
    content: '';
    width: 180px;
    height: 12px;
    position: absolute;
    bottom: -13px;
    right: 0px;
    z-index: -1;
    background: url('/salespage/9th section/long-scribbling-line.svg') no-repeat 0 0;
    background-size: contain;
    @media screen and (max-width: 379px) {
         right: -30px;
      }
      @media screen and (max-width: 340px) {
        right: 0px;
     }

    // @media screen and (max-width: 414px) {
    //   right: 238px;
    // }

    // @media screen and (max-width: 400px) {
    //   right: 48px;
    // }

    // @media screen and (max-width: 280px) {
    //   right: 108px;
    // }
  }
 
`;

export const FeatureParagraphMarkShort = styled.span`
  position: relative;


  &:after {
    content: '';
    width: 34px;
    height: 17px;
    position: absolute;
    bottom: -14px;
    left: 10px;
    z-index: -1;
    background: url('/salespage/4th section/short-blue-underline.svg') no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: 414px) {
      left: -290px;
    }
    @media screen and (max-width: 400px) {
      left: 0px;
    }
    @media screen and (max-width: 280px) {
      left: -170px;
    }
  }
`;